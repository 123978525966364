<template>
  <div class="min-h-screen">
    <section class="layout bg-blue-darker pb-44 pt-10vh">
      <div class="container pt-20 pb-10">
        <h1 class="text-4xl font-medium leading-normal text-white text-center">Lorem ipsum dolor</h1>
      </div>
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <skewed-separator class="text-white" />
    </section>
    <div class="container overlap">
      <div class="flex justify-center">
        <card class="w-full md:w-1/2 p-4">
          <notice v-if="errors != false" type="error" :messages="errors" />
          <form v-if="show">
            <input-container icon="key">
              <input type="email" v-model="form.email" placeholder="Email" required />
            </input-container>
            <btn class="btn-orange w-full" @click.native="submit">Forgot Password</btn>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '../partials/Card';
import SkewedSeparator from '../partials/SkewedSeparator';
import Btn from '../partials/Btn';
import Icon from '../partials/Icon';
import CircleBg from '../partials/CircleBg';
import InputContainer from '../partials/InputContainer';
import Notice from '../partials/Notice';

export default {
  components: { Notice, InputContainer, CircleBg, Icon, Btn, SkewedSeparator, Card },
  data() {
    return {
      errors: false,
      show: true,
      form: {
        email: '',
      },
    };
  },
  methods: {
    submit() {
      this.errors = false;

      this.$store.dispatch(window.store.POST_USER_FORGOT_PASSWORD, this.form)
        .then(() => {
          this.$router.push({ name: 'home' });
        })
        .catch((errors) => {
          this.errors = errors;
        });
    },
  },
};
</script>
